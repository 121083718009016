// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-oauth-tsx": () => import("./../../../src/pages/oauth.tsx" /* webpackChunkName: "component---src-pages-oauth-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-play-key-features-tsx": () => import("./../../../src/pages/play/key-features.tsx" /* webpackChunkName: "component---src-pages-play-key-features-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-version-tsx": () => import("./../../../src/pages/version.tsx" /* webpackChunkName: "component---src-pages-version-tsx" */),
  "component---src-templates-blog-hub-tsx": () => import("./../../../src/templates/blog-hub.tsx" /* webpackChunkName: "component---src-templates-blog-hub-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-getting-started-tsx": () => import("./../../../src/templates/getting-started.tsx" /* webpackChunkName: "component---src-templates-getting-started-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-plan-selection-tsx": () => import("./../../../src/templates/plan-selection.tsx" /* webpackChunkName: "component---src-templates-plan-selection-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-product-v-2-tsx": () => import("./../../../src/templates/product-v2.tsx" /* webpackChunkName: "component---src-templates-product-v-2-tsx" */)
}

