exports.onRouteUpdate = ({location}) => {
  const key = 'coupon_code';
  const urlParams = new URLSearchParams(location.search);
  const couponCode = urlParams.get(key);

  if (couponCode && window.localStorage) {
    const local = window.localStorage.getItem(key);

    if (local !== couponCode) {
      window.localStorage.setItem(key, couponCode);
    }
  }
};
