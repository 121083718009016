module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Collective","short_name":"starter","start_url":"/","display":"minimal-ui","icon":"src/images/the-collective-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33963f43a0b0675517e4b151f094185b"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://72d8ecd497b44d7d8950aebf4eef1921@o205637.ingest.sentry.io/5525371","environment":"prod","ignoreErrors":[],"tracesSampleRate":0.1},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5S378FN","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"countryDetection":true,"consentOnContinuedBrowsing":false,"perPurposeConsent":true,"whitelabel":false,"lang":"en","siteId":1963884,"cookiePolicyId":29650626,"banner":{"closeButtonDisplay":false,"acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#499BAE","acceptButtonCaptionColor":"white","customizeButtonColor":"#db704b","customizeButtonCaptionColor":"white","position":"float-bottom-center","textColor":"white","backgroundColor":"#2e2e2e"}},"googleTagManagerOptions":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
